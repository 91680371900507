<template>
  <div class="container">
    <!-- Main Row -->
    <div class="row">
      <div class="col-md-12 pb-0">
        <h5>Pilih Metode Pembayaran :</h5>
      </div>
      <!-- Form -->
      <div class="col-md-8 pt-0">
        <div class="card">
          <div class="card-body">
            <!-- method section -->
            <div class="row">
              <div
                class="col-md-3 px-2 py-2"
                v-for="(val, i) in method"
                :key="i"
              >
                <div
                  class="card method-card"
                  :id="val.code"
                  @click="setPaymentMethod(val.code,val.url_image)"
                >
                  <div class="card-body py-1 px-3">
                    <div
                      class="
                        d-flex
                        justify-content-center
                        align-items-center
                        image-container
                      "
                    >
                      <div
                        style="height: 100px; width: 100px; position: relative"
                      >
                        <img
                          :src="
                            val.url_image
                          "
                          class="image"
                        />
                      </div>
                    </div>
                    <div class="text-center px-2 pt-1">
                      <span class="font-payment-method">{{
                        val.payment_method_name
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end method section -->
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="row">
          <div class="col-12">
            <div class="card shadow-sm">
              <div class="card-body">
                <span style="font-size: 12px">Paket Berlangganan</span>
                <div class="d-flex justify-content-between mr-2">
                  <span
                    style="font-size: 12px; max-width: 100px"
                    class="font-weight-bold"
                    >{{ payment.name.toUpperCase() }}</span
                  >
                  <span style="font-size: 12px" class="font-weight-bold">{{
                    payment.duration + " x "+parseInt(payment.price).toLocaleString('id-ID')
                  }}</span>
                </div>
                <div class="d-flex justify-content-end mr-2">
                  <span style="font-size: 15px" class="font-weight-bold text-orange">{{
                    parseInt(payment.price*payment.duration).toLocaleString('id-ID')
                  }}</span>
                </div>
                <hr />
                <span style="font-size: 12px">Pajak</span>
                <div class="d-flex justify-content-between mr-2">
                  <span style="font-size: 12px" class="font-weight-bold"
                    >PPN 11%</span
                  >
                  <span style="font-size: 12px" class="font-weight-bold">{{
                    parseInt(payment.tax_amount).toLocaleString('id-ID')
                  }}</span>
                </div>
                <hr />
                <div class="d-flex justify-content-between mr-2">
                  <span
                    id="ppn"
                    style="font-size: 12px"
                    class="font-weight-bold"
                    >Biaya Layanan</span
                  >
                  <span
                    id="ppnPrice"
                    style="font-size: 12px"
                    class="font-weight-bold"
                    >GRATIS</span
                  >
                </div>
                <div
                  class="d-flex justify-content-between mr-2 mt-2"
                  style="
                    background-color: #efefef;
                    padding: 5px;
                    border-radius: 5px;
                  "
                >
                  <span style="font-size: 14px" class="font-weight-bold"
                    >TOTAL</span
                  >
                  <span style="font-size: 14px" class="font-weight-bold">{{
                    parseInt(payment.total_amount).toLocaleString('id-ID')
                  }}</span>
                </div>
                <div class="d-flex justify-content-between mr-2 mt-2">
                  <span style="font-size: 13px" class="font-weight-bold"
                    >Metode Pembayaran</span
                  >
                  <span style="font-size: 12px" class="font-weight-bold">{{
                    payment.payment_method_name
                  }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 mt-2 d-flex justify-content-center">
            <button
              id="checkout"
              class="btn btn-orange"
              @click="checkout"
              style="width: 100%; border-radius: 10px"
              disabled
            >
              Bayar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import Form from "@/component/settings/Form.vue";
import module from "@/core/modules/CrudModule.js";
import saasModule from "@/core/modules/CrudSaasModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import { setting, getSetting } from '@/core/services/jwt.service.js'

export default {
  components: {
    Card,
    Form,
  },

  data() {
    return {
      // Form
      form: {},
      method: {},
      // data
      payment: {},
      // Other
      dataLoaded: false,
    };
  },

  methods: {
    async get() {
      //   this.form = await module.get('settings/' + this.$route.params.id)
      this.method = await saasModule.get("subscribe/payment-method");
      this.method = this.method.items;
    },

    setPaymentMethod(code,image) {
      // delete all selected
      Array.from(document.querySelectorAll(".method-card")).forEach(function (
        el
      ) {
        el.classList.remove("method-card-selected");
      });

      // set selected
      let el = document.getElementById(code)
      el.classList.add("method-card-selected")

      // set data
      let find = this.method.find(x=> x.code == code)
      let name = find.payment_method_name
      let category = find.category

      // get data subdomain
      let sub = JSON.parse(localStorage.getItem('tenantconfig'))
      let subdomain = sub.domain.split(".")
      subdomain = subdomain[0]

      this.payment.payment_method_name = name
      this.payment.payment_method_category = parseInt(category)
      this.payment.payment_method_code = code
      this.payment.url_image = image
      this.payment.sub_domain = subdomain
      

      // set btn checkout not disabled
      document.getElementById("checkout").removeAttribute("disabled")
    },

    async checkout() {
      let response = await saasModule.submit(this.payment, "subscribe/create-invoice")
      if (response) {

        let data = response.success.data
        data.url_image = this.payment.url_image

        localStorage.setItem("payment",JSON.stringify(response.success.data))
        this.$router.push('/subscribe/payment')
        
      } 
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Berlangganan", route: "" },
      { title: "Checkout" },
    ]);

    let paymentDat = localStorage.getItem('paymentDetailData')
    paymentDat = JSON.parse(paymentDat)


    if(paymentDat != null && paymentDat != []){
      this.get();
      this.payment = paymentDat
    }else{
      this.$router.push('/subscribe/package')
    }
    // Get Data


  },
};
</script>

<style>
.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.image-input-wrapper {
  width: 38px !important;
  height: 38px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.method-card {
  border: 2px solid #dbdbdb !important;
  border-radius: 10px !important;
}

.method-card:hover {
  border: 2px solid #245590 !important;
  background-color: #efefef;
  cursor: pointer;
}

.method-card-selected {
  border: 2px solid #245590 !important;
  background-color: #efefef;
}

.btn-orange {
  background-color: #f47b20 !important;
  color: white;
}
.btn-orange:hover {
  background-color: #f47c20bb !important;
  color: white;
}

.font-payment-method {
  color: #245590 !important;
  /* font-weight: bolder; */
}
</style>